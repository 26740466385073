.container,
.mcontainer {
  position: absolute;
  height: 100%;
  z-index: 1000;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: #fff;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
}

.mcontainer {
  width: 100%;
}

.mcontainerCollapsed {
  display: none;
}

.floatingContainer {
  position: fixed;
  /* width: 48px;
  height: 48px; */
  top: 10px;
  left: 10px;
  z-index: 1000;
  border-radius: 50%;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
}

.controls {
  width: 48px;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.controlsCollapsed {
  visibility: hidden;
  width: 0;
}

.panel {
  position: relative;
  display: flex;
  flex-direction: column;
  visibility: visible;
  height: 100%;
  width: 320px;
  opacity: 1;
  transition: width 0.5s, visibility 1s, opacity 1s ease 0.2s;
}

.mpanel {
  position: relative;
  display: flex;
  flex-direction: column;
  visibility: visible;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.mpanelCollapsed {
  visibility: hidden;
  width: 0;
  opacity: 0;
}

.panelCollapsed {
  visibility: hidden;
  width: 0;
  opacity: 0;
  transition: width 0.5s, visibility 0s, opacity 0s;
  transition-delay: visibility 1s;
}

.panelHeader {
  width: 100%;
  height: 40px;
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  color: rgb(255, 255, 255);
  background-color: #0074d9;
}

.panelBody {
  display: flex;
  flex: 1;
  width: 100%;
  height: calc(100vh - 40px);
}

.headerTitle {
  text-align: center;
  flex-grow: 1;
}

.headerLabel {
  font-size: 1.4em;
  font-weight: 600;
}

.collapseIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  cursor: pointer;
  font-size: 1.6em;
  color: rgb(255, 255, 255);
}

.linkIcon,
.linkIconActive {
  text-decoration: none;
  font-size: 1.8em;
}

.linkIcon {
  color: rgb(51, 51, 51);
}

.linkIconActive {
  color: rgb(238, 105, 43);
}