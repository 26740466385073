.container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

.listWrapper {
  flex: 1 1 40%;
  /* overflow-y: auto; */
}

.deviceInfo {
  flex: 2 2 auto;
}

.panelInput {
  padding: 10px;
}


.filtersBlock {
  padding-left: 10px;
  padding-bottom: 10px;
}

.filterBlockHeader {
  font-weight: 500;
}

.listItem {
  /* padding-top: 0;
  padding-bottom: 0;
  align-items: center; */
  height: 36px;
  cursor: pointer;
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
}

.itemTitle {
  /* padding: 5px 10px; */
  margin-left: 6px;
}

.blacklisted {
  color: #a5471f;
}

.telematicStatus {
  margin-left: 10px;
  color: #f42509;
}

.indicator {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 2px;
  margin-right: 2px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.statusOffline {
  background: black;
}

.statusInvalid {
  background: red;
}

.statusRotten {
  background: gray;
}

.statusOld {
  background: yellow;
}

.statusOnline {
  background: green;
}

.drawerOverlay {
  position: fixed;
  width: 320px;
  height: calc(100% - 40px);
  left: 360px;
  overflow: hidden;
}

.inputContainer {
  display: flex;
  margin: 10px;
}

.inputContainer span {
  border-right: none !important;
  border-radius: 2px 0 0 2px !important;
}

.inputContainer button {
  width: 52px;
  border-radius: 0 2px 2px 0;
}

.filterButton,
.filterButton:focus {
  background: #1890ff !important;
  border-color: #1890ff !important;
  color: #fff !important;
}

.filterButton:hover {
  background: #0672d6 !important;
  border-color: #0672d6 !important;
  color: #fff !important;
}

.selectedFilterButton,
.selectedFilterButton:focus {
  background: #ff8018 !important;
  border-color: #ff8018 !important;
  color: #fff !important;
}

.selectedFilterButton:hover {
  background: #c55d08 !important;
  border-color: #c55d08 !important;
  color: #fff !important;
}

@media screen and (max-width: 920px) {
  .drawerOverlay {
    left: 48px;
    background-color: #fff;
    z-index: 8000;
  }
}