.panel {
  padding: 8px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  user-select: none;
  cursor: default;
}

.opened {
  width: 240px;
}

.closed {
  display: block;
  box-sizing: border-box;
  position: relative;
  padding: 5px 0;
  width: 26px;
  height: 26px;
  line-height: 0!important;
  color: #000;
  font-weight: 700;
  font-size: 1.4em;
  text-align: center;
  cursor: pointer;
}

.closed:hover {
  background-color: #f4f4f4;
}

.caption {
  font-size: 1.3em;
  padding-right: 10px;
}

.icon {
  float: right;
  font-size: 1.6em;
}
