.scroller {
  width: 100%;
  height: 100%;
  flex: 1 1;
  overflow-y: hidden;
}

.gridWrapper {
  width: 100%;
  height: 100%;
}

.customScroller {
  padding-right: 4px;
  width: 100%;
  overflow-y: auto;
}

@media print {
  .scroller {
    overflow: auto;
  }
}