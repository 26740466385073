.round-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border-color: #91ff00;
}
.triangle-icon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 40px 20px;
  border-color: transparent transparent #91ff00 transparent;
}
.with-shadow {
  filter: drop-shadow(0 0 10px #000000);
}

.top-tooltip {
  z-index: 900!important;
}
