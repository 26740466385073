.nl {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style: none;
  list-style-position: outside;
}

.cf:before,
.cf:after {
  display: table;
  content: "";
}

.cf:after {
  clear: both;
}

.lt-ie8 .cf {
  zoom: 1;
}

.blocks-justify {
  text-align: justify;
  text-justify: newspaper;
}

.z-depth-1 {
  z-index: 100;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}

.z-depth-2 {
  z-index: 200;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
}

.z-depth-3 {
  z-index: 300;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
}

.z-depth-4 {
  z-index: 400;
  box-shadow: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
}

.z-depth-5 {
  z-index: 500;
  box-shadow: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

/* ============================= General Markup ============================= */

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.section {
  margin: 10px;
}

.wide {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

/* ============================ /General Markup/ ============================ */

.app--on-top {
  z-index: 9999;
}

.z-depth-1 {
  z-index: 100;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}

.z-depth-2 {
  z-index: 200;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
}

.z-depth-3 {
  z-index: 300;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
}

.z-depth-4 {
  z-index: 400;
  box-shadow: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
}

.z-depth-5 {
  z-index: 500;
  box-shadow: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
}

.leaflet-popup-content {
  width: 100% !important;
  margin: 0 !important;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-list-item {
  justify-content: flex-start !important;
}


.specialModalContent {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.specialModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.onTop {
  z-index: 99999 !important;
}

@media print {
  .specialModalContent {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    border-radius: none;
  }
}

/*================================= Scrollbar ================================*/

/* W3C standard now only for Firefox (2021-06) */
* {
  scrollbar-width: auto;
  /* scrollbar-color: blue orange; */
}

/* For Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(229, 229, 234, 0.4);
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7e7e7e;
}

/*================================ !Scrollbar! ===============================*/

/*================================= Draw.FIX =================================*/

.sr-only {
  display: none;
}

.borderedControl {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.scrollable-panel .ant-tabs-content-holder {
  overflow: auto !important;
}

/*================================= !Draw.FIX! ===============================*/