.side-panel {
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  bottom: 0;
  margin: 0 auto;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  z-index: 2150;
  user-select: none;
  cursor: default;
}
@media (max-width: 720px) {
}
.side-panel .resizer {
  position: absolute;
  display: block;
  top: -12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 48px;
  height: 24px;
  text-align: center;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
}
.side-panel .caption {
  margin: 0 auto;
  padding-top: 8px;
  text-align: center;
  font-size: 1.3em;
  color: #464646;
}
.side-panel .ss-scroll-wrapper {
  height: 100%;
}
.ss-collapsed {
  display: none;
}
.ss-scroll-wrapper div:hover,
.ss-scroll-wrapper div:focus {
  outline: none !important;
}
.grid-cell {
  padding: 8px;
  cursor: pointer;
}
.grid-header {
  border-bottom: 1px solid #bd4b00;
  cursor: default;
}
.even-cell {
  background-color: #ffffff;
}
.odd-cell {
  background-color: #eeeeee;
}
.grid-cell--active {
  background-color: #ffa061;
}
