.loginContainer {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffa86e; /* #26A892; */
}

.loginTitle {
    margin-bottom: 10px;
    text-align: center;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    background: #ffffff;
    padding: 50px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

/* @media (min-width: 769px) and (max-width: 1024px) {
} */

/* @media (min-width: 481px) and (max-width: 768px) {
} */

@media (max-width: 480px) {
    .loginForm {
        width: 100%;
        height: 100%;
        margin-top: 0;
        display: block;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: none;
    }
}

.errorBlock {
    padding-top: 8px;
    margin-bottom: 8px;
    background-color:  rgb(238, 176, 165);
    border: 1px solid rgb(240, 80, 51);
    border-radius: 4px;
}

.formButton {
    width: 100%;
}

.demoButton {
    display: block;
    margin-top: 10px;
}

.rightAligned {
    text-align: left;
}

.formTip {
    margin-top: 15px;
}

.formTip .tipText {
    margin-right: 5px;
}

.formTip .tipBtn {
    color: orange;
    cursor: pointer;
}

.loginLang {
    padding-top: 15px;
}

.formInput {
    color: 'rgba(0,0,0,.25)';
}

.idLink {
    margin-top: 20px;
    float: 'right';
}