.cluser-level-1 {
  background-color: rgba(156, 236, 131, 0.6);
  color: #006e00;
  border-radius: 20px;
  font-size: 1.5em;
}
.cluser-level-1 div {
  background-color: rgba(61, 196, 35, 0.6);
  border-radius: 15px;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
}
.cluser-level-2 {
  background-color: rgba(140, 174, 177, 0.6);
  color: #006269;
  border-radius: 25px;
  font-size: 1.5em;
}
.cluser-level-2 div {
  background-color: rgba(5, 141, 151, 0.6);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
}
.cluser-level-3 {
  background-color: rgba(192, 177, 149, 0.6);
  color: #6d4700;
  border-radius: 30px;
  font-size: 2em;
}
.cluser-level-3 div {
  background-color: rgba(143, 95, 5, 0.6);
  border-radius: 25px;
  width: 50px;
  height: 50px;
  margin-left: 5px;
  margin-top: 5px;
}
.cluser-level-4 {
  background-color: rgba(165, 130, 211, 0.6);
  color: #310070;
  border-radius: 35px;
  font-size: 2em;
}
.cluser-level-4 div {
  background-color: rgba(67, 7, 145, 0.6);
  border-radius: 30px;
  width: 60px;
  height: 60px;
  margin-left: 5px;
  margin-top: 5px;
}
.cluser-level-1,
.cluser-level-2,
.cluser-level-3,
.cluser-level-4 {
  background-clip: padding-box;
}
.cluser-level-1 div,
.cluser-level-2 div,
.cluser-level-3 div,
.cluser-level-4 div {
  text-align: center;
}
.cluser-level-1 span {
  line-height: 30px;
}
.cluser-level-2 span {
  line-height: 40px;
}
.cluser-level-3 span {
  line-height: 50px;
}
.cluser-level-4 span {
  line-height: 60px;
}
