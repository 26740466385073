.simple-pannel {
  display: block;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
}
.pannel-offset-1 {
  position: absolute;
  margin-top: 0;
  margin-left: 20px;
}
.pannel-nested {
  display: none;
}
.pannel--active {
  display: block;
}
[class*='close-'] {
  color: #777;
  font-size: 20px;
  line-height: 1em;
  position: absolute;
  right: 5px;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  top: 5px;
  cursor: pointer;
}
[class*='close-']:hover,
[class*='close-']:active,
[class*='close-']:focus {
  text-decoration: none;
}
.close-thin:after {
  content: '×';
}
