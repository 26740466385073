.container {
    padding: 10px;
}

.panelContainer {
    padding: 15px;
}

.panelContainer p:not(:last-child) {
    margin-bottom: 4px;
}

.logSelect {
    width: 120px;
}
