.nl {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style: none;
  list-style-position: outside;
}
.cf:before,
.cf:after {
  display: table;
  content: "";
}
.cf:after {
  clear: both;
}
.lt-ie8 .cf {
  zoom: 1;
}
.blocks-justify {
  text-align: justify;
  text-justify: newspaper;
}
.z-depth-1 {
  z-index: 100;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}
.z-depth-2 {
  z-index: 200;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
}
.z-depth-3 {
  z-index: 300;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
}
.z-depth-4 {
  z-index: 400;
  box-shadow: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
}
.z-depth-5 {
  z-index: 500;
  box-shadow: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
}
.map-layout {
  position: absolute;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  z-index: 0;
}
.leaflet-container {
  position: absolute;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  z-index: 0;
}
.tooltipsss {
  margin-left: 20px;
}
