.top-bar {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  z-index: 2000;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  padding: 8px;
  text-align: center;
}
@media (max-width: 720px) {
  .top-bar {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    border-radius: 0;
  }
}
.left-bar {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 64px;
  left: 8px;
  width: 320px;
  max-height: 40vh;
  z-index: 2000;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  background-color: #fff;
  padding: 8px;
}
@media (max-width: 720px) {
  .left-bar {
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 480px;
    max-height: 50vh;
    margin: 0 auto;
  }
}
.left-bar .resizer {
  display: none;
}
@media (max-width: 720px) {
  .left-bar .resizer {
    position: absolute;
    display: block;
    top: -12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 48px;
    height: 24px;
    text-align: center;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  }
}
.left-bar .scroll-wrapper {
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;
}
.left-bar .scroll-wrapper.collapsed {
  display: none;
}
